<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="服装名称：">
          <el-input v-model="listQuery.Name" placeholder="请输入服装名称" class="input-width" />
        </el-form-item>
        <el-form-item label="服装颜色：">
          <el-input v-model="listQuery.Color" placeholder="请输入服装颜色" class="input-width" />
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration"> 查询 </el-button>
          <el-button class="plain-button" v-preventReClick @click="resetSearch"> 重置 </el-button>
          <el-button @click="addOrEidtClubClothing()" icon="el-icon-plus">
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span>添加服装</span></div> -->
            添加服装
          </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体内容 -->
    <main class="page-main-main" ref="main">
      <!-- 表格渲染 -->
      <el-table v-loading="loading" :data="tableData" border fit stripe>
        <el-table-column prop="Name" label="训练服装名称" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="Color" label="颜色" align="center" />
        <el-table-column label="图片" align="center">
          <template slot-scope="{ row }">
            <div class="img-box">
              <el-image class="image-box" :src="`${cosHttp}${row.ImageAddress}`" :preview-src-list="[`${cosHttp}${row.ImageAddress}`]" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Remarks" label="备注" align="center">
          <template slot-scope="{row}">
            <el-tooltip effect="dark" placement="top">
              <div v-html="row.Remarks" slot="content"></div>
              <div class="oneLine">{{row.Remarks}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding" width="100px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="addOrEidtClubClothing(row)"> 编辑 </el-button>
            <el-button type="text" @click="deleteClubClothing(row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页部分 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubClothingList" />
      </footer>
    </main>
    <!-- 新建编辑服装 -->
    <el-dialog :title="clubClothingTitle" :visible.sync="clubClothingVisible" width="40%" :close-on-click-modal='false'>
      <el-form :model="clubClothingForm" :rules="rules" ref="clubClothingForm" label-width="100px">
        <el-form-item label="服装名称:" prop="Name">
          <el-input v-model="clubClothingForm.Name" placeholder="请输入服装名称" show-word-limit maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="颜色:" prop="Color">
          <el-input v-model="clubClothingForm.Color" placeholder="请输入服装颜色" show-word-limit maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="图片:" prop="ImageAddress">
          <!-- element 上传图片按钮 -->
          <template>
            <div class="cropper-wrapper">
              <!-- element 上传图片按钮 -->
              <template v-if="!isPreview">
                <el-upload action="#" :show-file-list="false" :on-change="handleChangeUpload">
                  <img v-if="clubClothingForm.ImageAddress" :src="`${cosHttp}${clubClothingForm.ImageAddress}`" class="avatar">
                  <i v-else class="el-icon-full-screen avatar-uploader-icon avatar-uploader">
                    <br>
                    <span>上传图片</span></i>
                </el-upload>
                <span class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</span>
              </template>
              <div class="pre-box" v-else>
                <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
                  <!-- <el-button type="primary" plain>更换图片</el-button> -->
                  <img v-if="previewImg" :src="previewImg" alt="裁剪图片" class="avatar" />
                </el-upload>
                <span class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</span>
              </div>
              <!-- vueCropper 剪裁图片实现-->
              <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body :close-on-click-modal='false'>
                <div class="cropper-content">
                  <div class="cropper" style="text-align: center">
                    <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed" :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue" :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" @cropMoving="cropMoving" />
                  </div>
                </div>
                <div class="action-box">
                  <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
                    <el-button type="primary" plain>更换图片</el-button>
                  </el-upload>
                  <el-button type="primary" plain @click="clearImgHandle">清除图片</el-button>
                  <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
                  <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
                  <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
                  <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
                  <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button class="discoloration" @click="finish" :loading="loading">确 认</el-button>
                </div>
              </el-dialog>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="备注:" prop="Remarks">
          <el-input v-model="clubClothingForm.Remarks" :autosize="{ minRows: 3, maxRows: 5 }" type="textarea" placeholder="请输入服装备注" show-word-limit maxlength="200" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clubClothingVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="addOrUpdateClubClothing()" class="discoloration"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClubClothingList, delClubClothing, addOrUpdateClubClothing, getClubClothingById } from "@/service/organizational";
import pagination from '@/components/pagination/pagination.vue';  // 引入分页
import { deepClone } from '@/utils/index';  // 引入深拷贝
import upload from '@/mixins/upload';
import { VueCropper } from 'vue-cropper';


const ClubClothingForm = {  // 新增编辑服装的初始化数据
  Name: '',
  Color: '',
  ImageAddress: '',
  Remarks: '',
};
const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  Color: '',
  Name: '',
};
export default {
  name: "Clothing",
  mixins: [upload],
  components: {
    pagination,
    VueCropper
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入服装名称'));
      }
      callback();
    };

    const validateColor = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入服装颜色'));
      }
      callback();
    };

    const validateImageAddress = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请上传图片'));
      }
      callback();
    };

    return {
      fileName: "defualt",
      clubClothingForm: deepClone(ClubClothingForm),
      listQuery: deepClone(ListQuery),
      tableData: [],  // 表格数据
      loading: false, // 加载状态
      total: 0, // 表格总条数
      clubClothingTitle: '新增服装',  // 新建编辑title
      clubClothingVisible: false, // 新建编辑服装弹窗状态
      imageUrl: '', // 新建编辑服装上传图片
      rules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        Color: [{ required: true, validator: validateColor, trigger: 'blur' }],
        ImageAddress: [{ required: true, validator: validateImageAddress, trigger: 'blur' }],
      },
      cosHttp: process.env.VUE_APP_COSHTTP,
      isPreview: false,
      dialogVisible: false,
      previewImg: '', // 预览图片地址
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false
    }
  },
  created() {
    this.getClubClothingList();
  },
  methods: {

    /**
     * 获取服装列表数据
     */
    async getClubClothingList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubClothingList(this.listQuery);
      this.loading = false;
      this.tableData = res.DataList;
      this.total = res.Total;
    },

    /**
     * 页头-查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.listQuery.Color = this.listQuery.Color && this.listQuery.Color.trim();
      this.getClubClothingList();
    },

    /**
     * 页头-重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.getClubClothingList();
    },

    /**
     * 操作/页头-新增或者编辑
     */
    addOrEidtClubClothing(row) {
      this.clubClothingVisible = true;
      this.isPreview = false;
      this.$nextTick(() => {
        this.$refs.clubClothingForm.clearValidate();

      })
      if (row) {
        this.clubClothingForm = { ...row };
        this.clubClothingTitle = '编辑服装';
      } else {
        this.clubClothingForm = deepClone(ClubClothingForm);
        this.clubClothingTitle = '新增服装';
      }
    },

    /**
     * 确定新增或者编辑
     */
    addOrUpdateClubClothing() {
      this.$refs.clubClothingForm.validate(async (valid) => {
        if (!valid) return;

        if (this.clubClothingTitle === '编辑服装') {
          await addOrUpdateClubClothing({
            Id: this.clubClothingForm.Id,
            Color: this.clubClothingForm.Color,
            ImageAddress: this.clubClothingForm.ImageAddress,
            Name: this.clubClothingForm.Name,
            Remarks: this.clubClothingForm.Remarks
          });
          this.$message.success('编辑成功');
        } else {
          await addOrUpdateClubClothing(this.clubClothingForm);
          this.$message.success('新建成功');
        }
        this.clubClothingVisible = false;
        this.getClubClothingList();
      })

    },

    /**
     * 操作-删除
     */
    async deleteClubClothing(row) {
      try {
        let res = await this.$confirm('确定要删除吗？', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (res !== 'confirm') return;
        await delClubClothing({ Id: row.Id });
        this.getClubClothingList();
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      } catch (err) { };
    },

    // 正在上传中...
    uploading(progressData) {
      let index = this.fileList.length - 1;
      this.fileList[index].percentage = progressData.percent * 100;
      this.fileList[index].status = 'uploading';
    },
    // 上传按钮 限制图片大小和类型
    handleChangeUpload(file, fileList) {
      if (JSON.parse(JSON.stringify(file)).status === 'ready') {
        const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/jpg';
        const isLt20M = file.raw.size / 1024 / 1024 < 20;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
          return false;
        }
        if (!isLt20M) {
          this.$message.error('上传头像图片大小不能超过 20MB!');
          return false;
        }
        this.fileName = file.name.split('.')[0];
        // 上传成功后将图片地址赋值给裁剪框显示图片
        this.$nextTick(async () => {
          this.loading = true;
          // base64方式
          // this.option.img = await fileByBase64(file.raw)
          this.option.img = URL.createObjectURL(file.raw)
          this.loading = false;
          this.dialogVisible = true;
        })
      }
    },
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    // 下载
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data);
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = data;
          aLink.href = data;
          aLink.click();
        })
      }
    },
    // 清理图片
    clearImgHandle() {
      this.option.img = '';
    },
    // 截图框移动回调函数
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
    },
    async finish() {
      // 获取截图的 blob 数据
      this.$refs.cropper.getCropData((data) => {
        this.loading = true;
        this.dialogVisible = false;
        this.previewImg = data;
        this.isPreview = true;
        this.loading = false;

        let arr = data.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let suffix = mime.split('/')[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let file = new File([u8arr], `${this.fileName}.${suffix}`, {
          type: mime
        });
        this.uploadTecent(file).then(url => {
          this.clubClothingForm.ImageAddress = url;
        });
      })
    },
  },
}

</script>

<style lang="scss" scoped>
p {
  font-size: 12px;
}
/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
.cropper-content {
  .cropper {
    width: auto;
    height: 350px;
  }
}
.action-box {
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 0;
  }
  margin-top: 24px;
}
/deep/.el-dialog__footer {
  padding-right: 24px !important;
}
</style>
